import React from "react";
import { graphql } from "gatsby";

import { FaqContent } from "../page_components/faq";

import Layout from "components/Layout";
import StaticPageHeader from "components/StaticPageHeader";

const Home = ({ data }) => {
  const seo = data?.faq?.seo;
  const questions = data.faq.faqPage.questions;

  return (
    <Layout
      location="FAQ"
      seo={{ title: seo?.title, description: seo?.metaDesc }}
      blue_header
    >
      <StaticPageHeader title="Faq" />
      <FaqContent data={questions} />
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query faq {
    faq: wpPage(id: { eq: "cG9zdDoxMjky" }) {
      faqPage {
        questions {
          answer
          question
        }
      }
      title
      seo {
        title
        metaDesc
        schema {
          raw
        }
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphUrl
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`;
