import "./styles.scss";

import React, { useState } from "react";
import classnames from "classnames";

import ArrowIcon from "icons/slider-arrow.svg";

const mainClass = "faq-list";

const FaqContent = ({ data }) => {
  const [selected_item, setSelectedItem] = useState(null);

  const selectedItemHandler = (index) => {
    if (selected_item === index) {
      setSelectedItem(null);
      return;
    }

    setSelectedItem(index);
  };

  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__items`}>
          {data?.map(({ question, answer }, index) => (
            <div
              key={index}
              className={classnames(`${mainClass}__items__item`, {
                [`${mainClass}__items__item--active`]: selected_item === index,
              })}
            >
              <div
                onClick={() => selectedItemHandler(index)}
                className={`${mainClass}__items__item__header`}
              >
                <h3>{question}</h3>
                <ArrowIcon />
              </div>
              {selected_item === index && (
                <div
                  className={`${mainClass}__items__item__content`}
                  dangerouslySetInnerHTML={{
                    __html: answer,
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqContent;
