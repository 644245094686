import "./styles.scss";
import React from "react";

const mainClass = "static-page-header";

const StaticPageHeader = ({ title }) => {
  return (
    <div className={mainClass}>
      <div className="container">
        <h1>{title}</h1>
      </div>
    </div>
  );
};

export default StaticPageHeader;
